<template>
    <list-condition-template ref="table" :rows="rows" :total="total" @page-info-change="handlePageInfoChange"
     :defaultSort="{prop:'updateTime',order:'descending'}" v-model:listQuery="listQuery" :index-column="true">
    <template #condition>
      <el-form-item>
        <div class="demo-date-picker">
          <div class="block">
            <span class="demonstration">接收日期：</span>
            <el-date-picker
              clearable
              value-format="YYYY-MM-DD"
              v-model="valueDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
            </el-date-picker>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-input @keyup.enter="handleFilter" placeholder="输入订单批次查询" v-model="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select @change="handleFilter" clearable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="所有厂家接收状态" v-model="listQuery.condition.isAcceptAll">
          <el-option v-for="item in isAcceptAllOptions" :key="item.name" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-form-item>
    </template>
    <template #columns>
        <el-table-column min-width="150px" label="订单批次" property="orderBatch" align="center">
          <template #default="scope">
            <span>{{scope.row.orderBatch}}</span>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="所有厂家是否完成接收" align="center">
          <template #default="scope">
            <el-tag :type="showFilter(scope.row)">{{scope.row.notAcceptManufacturerNum===0?'已完成':'未完成'}}</el-tag>
          </template>
      </el-table-column>
        <el-table-column min-width="150px" label="接收时间" align="center" property="receiptTime">
          <template  #default="scope">
            <span>{{scope.row.receiptTime}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="完成分发时间" align="center" property="distributeTime">
          <template  #default="scope">
            <span>{{scope.row.distributeTime}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="80px" label="防伪标签（套）" property="securityNumber" align="center">
          <template #default="scope">
            <span>{{scope.row.securityNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="80px" label="采购条码数（枚）" property="barcodeNumber" align="center">
          <template #default="scope">
            <span>{{scope.row.barcodeNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="标签供应商" property="supplier.name" align="center">
          <template #default="scope">
            <span>{{scope.row.supplier.name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150px" class-name="fixed-width">
          <template  #default="scope">
            <el-button size="mini" type="primary" @click="handleShow(scope.row)" v-waves>查看</el-button>
          </template>
        </el-table-column>
    </template>
    <el-dialog v-model="startupFormDialogShow" :append-to-body="true" width="80%">
        <component :is="startupForm" v-model:show="startupFormDialogShow" v-model:orderId="orderId" @close="startupFormDialogShow=false;getList()"></component>
    </el-dialog>

  </list-condition-template>
</template>

<script>
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'
import myDistributeApi from '@/apis/distribute/distribute'
import addDistributeForm from './addDistribute'
export default {
  directives: {
    waves
  },
  components: {
    listConditionTemplate,
    addDistributeForm
  },
  data () {
    return {
      isAcceptAllOptions: [
        {
          name: '已完成',
          value: 1
        },
        {
          name: '未完成',
          value: 2
        }
      ],
      valueDate: undefined,
      rows: null,
      total: 0,
      orderId: undefined,
      startupFormDialogShow: false,
      startupForm: null,
      listQuery: {
        condition:{
          keyword: null,
          enabled: null,
          startReceiptTime: undefined,
          endReceiptTime: undefined,
          isAcceptAll: undefined
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      }
    }
  },
  filters: {
  },
  methods: {
     showFilter (row) {
      if(row.notAcceptManufacturerNum===0){
        return 'success'
      }else {
        return ''
      }
    },
    handlePageInfoChange () {
      this.getList()
    },
    getList () {
      myDistributeApi
        .getPaged(this.listQuery)
        .then(response => {
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    handleShow(row){
      this.$router.push({name:this.$router.routesConfig.distributeDetails.name, params:{id:row.id, isShow: 'myDistribute'}})
    },
    /**
     * 搜索
     */
    handleFilter () {
      if(this.valueDate){
        this.listQuery.condition.startReceiptTime = this.valueDate[0] + " 00:00:00"
        this.listQuery.condition.endReceiptTime = this.valueDate[1] + " 23:59:59"
      }else{
        this.listQuery.condition.startReceiptTime = undefined
        this.listQuery.condition.endReceiptTime = undefined
      }
      this.getList()
    }
  }
}
</script>
